/* eslint-disable max-len */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Image from "next/image";
import { useContentfulContext } from "../../context/ContentfulContext";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Video = styled.video`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const Aboutus = () => {
  const { allEntries } = useContentfulContext();
  const { t } = useTranslation();
  let asset = { text: "", url: "", title: "" };
  if (allEntries.items) {
    asset = allEntries.items.find(
      (entry) => entry.sys.contentType.sys.id === "about",
    );
    asset = asset.fields;
    asset.url = `https:${asset.image.fields.file.url}`;
  }

  return (
    <div className="section">
      <div className="container">
        <div className="about">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-lg-30 ct-single-img-wrapper">
              <Video autoPlay loop muted>
                <source
                  src="/assets/himalayanvideo.mp4"
                  nr1
                  type="video/mp4"
                ></source>
              </Video>
            </div>
            <div className="col-lg-6">
              <div className="section-title-wrap mr-lg-30">
                <h5 className="custom-primary">{t("about")}</h5>
                <h2 className="title">{asset.title}</h2>
                <p className="subtitle">
                  {documentToReactComponents(asset.text)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
