/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/link-passhref */
/* eslint-disable max-len */
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { smoothScroll } from "../../helper/scrollToElement";
import Link from "next/link";
import { useContentfulContext } from "../../context/ContentfulContext";
import Image from "next/image";
import useMediaQuery from "../../helper/useMediaQuery";

const Footer = (props) => {
  const { t } = useTranslation();
  const { allEntries } = useContentfulContext();
  let info = {
    phone: "",
    email: "",
    facebookLink: "https://www.facebook.com/Himalayanspiceiceland/",
    instagramLink: "https://www.instagram.com/himalayanspiceiceland",
  };
  let street = "";
  let openingHour = "";
  let extraText = "";
  if (allEntries.items) {
    const asset = allEntries.items.find(
      (entry) => entry.sys.contentType.sys.id === "contactInfo",
    );
    info = asset.fields;

    const locations = allEntries.items.find(
      (entry) => entry.sys.contentType.sys.id === "location",
    );
    const location = locations.fields;
    street = `${location.street}, ${location.postal}`;
    openingHour = `${location.open} - ${location.close}`;
    extraText = location.extraText;
  }

  const isTablet = useMediaQuery(768);
  const isDesktop = useMediaQuery(992);

  return (
    <footer className={props.footer.style} style={{ paddingBottom: 80 }}>
      {/* Top Footer */}

      {/* Middle Footer */}
      <div className="footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 footer-widget">
              <h5 className="widget-title">{t("about")}</h5>
              <ul>
                <li>
                  <NavLink
                    onClick={() => smoothScroll(".menus", 2000, "header")}
                  >
                    {t("menu")}
                  </NavLink>{" "}
                </li>
                <li>
                  <NavLink
                    onClick={() =>
                      smoothScroll(".opening-hours", 2000, "header")
                    }
                  >
                    {t("openingHours")}
                  </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink
                    onClick={() => smoothScroll(".about", 1000, "header")}
                  >
                    {t("about")}
                  </NavLink>{" "}
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 footer-widget">
              <h5 className="widget-title">{t("contactInfo")}</h5>
              <ul>
                <li>
                  <ContactInfo href={`tel:${info.phone}`}>
                    {info.phone}
                  </ContactInfo>
                </li>
                <li>
                  <ContactInfo href={`mailto:${info.email}`}>
                    {info.email}
                  </ContactInfo>
                </li>
              </ul>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 footer-widget">
              <h5 className="widget-title">{t("openingHours")}</h5>
              <ul>
                <li>
                  <p>
                    {street}
                    <br />
                    {openingHour}
                    <br />
                    {extraText}
                  </p>
                </li>
              </ul>
              <h5 className="widget-title">{t("socials")}</h5>
              <ul>
                <li>
                  <Link
                    href={info.facebookLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      style={{
                        fontSize: "24px",
                        marginRight: 8,
                        color: "white",
                        cursor: "pointer",
                      }}
                      className="fab fa-facebook-square"
                    />
                  </Link>
                  <Link
                    href={info.instagramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      style={{
                        fontSize: "24px",
                        marginRight: 8,
                        color: "white",
                        cursor: "pointer",
                      }}
                      className="fab fa-instagram"
                    />
                  </Link>
                  <Link
                    href={
                      "https://www.tripadvisor.com/Restaurant_Review-g189970-d15610605-Reviews-Himalayan_Spice-Reykjavik_Capital_Region.html"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      style={{
                        fontSize: "24px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      className="fab fa-tripadvisor"
                    />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 footer-widget">
                <a href="https://www.tripadvisor.com/Restaurant_Review-g189970-d15610605-Reviews-Himalayan_Spice-Reykjavik_Capital_Region.html" target="_blank" rel="noopener noreferrer">
                  <div style={{ width: isDesktop ? "inherit": 240, margin: isTablet ? "inherit" : "auto",  aspectRatio: 1080 / 1350 }}>
                    <Image
                      layout="responsive"
                      alt="tripadvisor-choice-2022"
                      src="/assets/tc-22.png"
                      width={1080}
                      height={1350}
                    ></Image>
                  </div>
                </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const NavLink = styled.a`
  cursor: pointer;
  color: #b7b7b7;
  text-transform: uppercase;
`;

const ContactInfo = styled(NavLink)`
  text-transform: lowercase;
`;

export default Footer;
