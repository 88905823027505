/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { NextPage } from "next";
import Head from "next/head";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import Content from "../components/sections/Content";
import styled from "styled-components";
import { useContentfulContext } from "../context/ContentfulContext";
import { useTranslation } from "react-i18next";

// import PurgeCSS from "purgecss";
// const purgeCSSResults = await new PurgeCSS().purge({
//   content: ["**/*.html"],
//   css: ["**/*.css"],
// });
const Home: NextPage = (props) => {
  const { t } = useTranslation();

  const { allEntries } = useContentfulContext();
  let useSubHeader = false;
  let text = `${t("open")} 17:00 - 21:00`;
  if (allEntries.items) {
    let asset = allEntries.items.find(
      (entry: any) => entry.sys.contentType.sys.id === "subHeader",
    );
    asset = asset.fields;
    if (asset.useSubHeader) {
      useSubHeader = asset.useSubHeader;
      // get the day
      if (useSubHeader) {
        const today = new Date();
        if (asset.daysOfWeek.includes(today.getDay().toString())) {
          const start = asset[`open${today.getDay()}`];
          const close = asset[`close${today.getDay()}`];
          text = `${t("open")}: ${start} - ${close}`;
        } else {
          text = `${t("closedToday")}`;
        }
      }
    }
  }

  return (
    <>
      <Head>
        <title>Himalayan Spice, ekta nepalskur matur</title>
        <meta
          name="description"
          content="Ekta nepalskur matur. Matargerðin okkar er jafn fjölbreytt og fólkið og menning í Nepal er. Fjölbreytt úrval gómsætra og heilsusamlegra rétta og drykkja. Njóttu máltíðarinnar og Nepalskrar gestrisni. Namaste!"
        />
        <link rel="icon" href="/favicon.ico" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://himalayanspice.is/" />
        <meta property="og:title" content="Himalayan Spice" />
        <meta
          property="og:description"
          content="Ekta nepalskur matur. Matargerðin okkar er jafn fjölbreytt og fólkið og menning í Nepal er. Fjölbreytt úrval gómsætra og heilsusamlegra rétta og drykkja. Njóttu máltíðarinnar og Nepalskrar gestrisni. Namaste!"
        />
        <meta
          property="og:image"
          content="https://media-cdn.tripadvisor.com/media/photo-s/21/7c/32/cf/nepalese-restaurant-in.jpg"
        />
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://himalayanspice.is/" />
        <meta property="twitter:title" content="Himalayan Spice" />
        <meta
          property="twitter:description"
          content="Ekta nepalskur matur. Matargerðin okkar er jafn fjölbreytt og fólkið og menning í Nepal er. Fjölbreytt úrval gómsætra og heilsusamlegra rétta og drykkja. Njóttu máltíðarinnar og Nepalskrar gestrisni. Namaste!"
        />
        <meta
          property="twitter:image"
          content="https://media-cdn.tripadvisor.com/media/photo-s/21/7c/32/cf/nepalese-restaurant-in.jpg"
        />
      </Head>
      <Header {...props} />
      {useSubHeader && <InfoWrapper>{text}</InfoWrapper>}
      <Content />
      <Footer
        footer={{
          style: "ct-footer footer-dark",
          logo: "assets/logo-crop.png",
        }}
      />
    </>
  );
};

export const getStaticProps = async ({ locale }: { locale: string }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
};

export default Home;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  z-index: 100;
   

  @media screen and (min-width: 375px) {
    flex-direction: row;
  }
`;
