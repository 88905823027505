import React, { useRef } from "react";
import Image from "next/image";
import Slider from "react-slick";
// import testimonials from "../../data/testimonials.json";
import { useContentfulContext } from "../../context/ContentfulContext";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const { allEntries } = useContentfulContext();
  const { t } = useTranslation();
  const testimonials = [];
  if (allEntries.items) {
    const assets = allEntries.items.filter(
      (entry) => entry.sys.contentType.sys.id === "testimonial",
    );
    assets.forEach((testimonial) => {
      const { title, comment, username, from, profilePicture } =
        testimonial.fields;
      const { url } = profilePicture.fields.file;

      testimonials.push({
        title,
        comment,
        username,
        from,
        profilePicture,
        url: `https:${url}`,
      });
    });
  }

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  let slider = useRef(null);

  return (
    <section className="section testimonials">
      <div className="container">
        <div className="section-title-wrap section-header text-center">
          <h5 className="custom-primary">{t("customerReviews")}</h5>
          <h2 className="title">{t("whatOurCustomersSay")}</h2>
          <p className="subtitle"></p>
        </div>
        <Slider
          className="ct-testimonials-slider"
          {...settings}
          // ref={(c) => (slider = c)}
          ref={(c) => (slider = c)}
        >
          {/* Testimonial item start */}
          {testimonials.map((item, i) => (
            <div key={i} className="ct-testimonial-slider-item">
              <div className="ct-testimonial-item">
                <div className="ct-testimonial-thumb">
                  <Image
                    src={item.url}
                    alt={item.username}
                    width={100}
                    height={100}
                  />
                </div>
                <div className="ct-testimonial-content">
                  <i className="flaticon-left-quote" />
                  <h5>{item.title}</h5>
                  <p>{item.comment}</p>
                  <h5>{item.username}</h5>
                  <span>{item.from}</span>
                </div>
              </div>
            </div>
          ))}
          {/* Testimonial item end */}
        </Slider>
        <div className="ct-arrows centered-arrows with-margin">
          <i
            className="fas fa-arrow-left slider-prev slick-arrow"
            onClick={() => slider.slickPrev()}
          />
          <i
            className="fas fa-arrow-right slider-next slick-arrow"
            onClick={() => slider.slickNext()}
          />
        </div>
      </div>
    </section>
  );
  // }
};

export default Testimonials;
