/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
/* eslint-disable require-jsdoc */
// https://spicyyoghurt.com/tools/easing-functions

export const smoothScroll = (
  target: string,
  duration: number,
  nav?: string,
) => {
  const curr_target: HTMLElement = document.querySelector(target)!;
  
  let pos = 0;
  if (nav) {
    const navigator: HTMLElement = document.querySelector(nav)!;

    pos = curr_target.getBoundingClientRect().top - navigator.offsetHeight;
  } else {
    pos = curr_target.getBoundingClientRect().top;
  }

  const startPos = window.pageYOffset;
  // const distance = pos - startPos;
  let startTime: number | null = null;
  let continueAnimation =true;
  const stopScroll = () => {continueAnimation = false}
  function animation(currentTime: number) {
    if(continueAnimation){
      if (startTime === null) {
        startTime = currentTime;
      }
      const timeElapsed = currentTime - startTime;
      window.addEventListener('wheel', stopScroll)
      const run = easeInOutCubic(timeElapsed, startPos, pos, duration);
      window.scroll(0, run);
      
      if (timeElapsed < duration) requestAnimationFrame(animation);
    }
    else{
      window.removeEventListener('wheel', stopScroll);
    }
    function easeInOutCubic(t: number, b: number, c: number, d: number) {
      if ((t /= d / 2) < 1) return (c / 2) * t * t * t + b;
      return (c / 2) * ((t -= 2) * t * t + 2) + b;
    }
  }
  requestAnimationFrame(animation);

};
