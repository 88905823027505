import React, { Component, Fragment } from "react";
import Aboutus from "./Aboutus";
import Categories from "./Categories";
// import Instagram from "./Instagram";
import Testimonials from "./Testimonials";
import Hero from "./Hero";
import dynamic from "next/dynamic";

const MapNoSSR = dynamic(() => import("./Locations"), { ssr: false });
// const Aboutus = dynamic(() => import("./Aboutus"));
// const Categories = dynamic(() => import("./Categories"));
// const Instagram = dynamic(() => import("./Instagram"));
// const Testimonials = dynamic(() => import("./Testimonials"));
// const Hero = dynamic(() => import("./Hero"));
class Content extends Component {
  render() {
    return (
      <Fragment>
        <Hero />
        <Categories />
        <MapNoSSR />
        <Aboutus />
        <Testimonials />
        {/* <Instagram /> */}
      </Fragment>
    );
  }
}

export default Content;
