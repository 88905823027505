/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styled from "styled-components";
import useMediaQuery from "../../helper/useMediaQuery";
import { useContentfulContext } from "../../context/ContentfulContext";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

const Hero = () => {
  const isTablet = useMediaQuery(768);
  const biggerThenMobile = useMediaQuery(480);
  const { t } = useTranslation();
  const logo = "logo-crop.png";
  const { allEntries } = useContentfulContext();
  const router = useRouter();
  let asset = { title: "", url: "", subtitle: "" };

  if (allEntries.items) {
    asset = allEntries.items.find(
      (entry) => entry.sys.contentType.sys.id === "hero",
    );
    asset = asset.fields;
    asset.url = asset.image.fields.file.url;
  }

  return (
    <Parent
      style={{
        marginTop: -28,
        backgroundPosition: !isTablet ? "center" : "inital",
      }}
      url={asset.url}
    >
      <Content className="container" data-aos="fade-in">
        <Logo url={logo} />
        <Title data-aos="fade-up" data-aos-delay="400">
          {asset.title}
        </Title>
        <Subtitle data-aos="fade-up" data-aos-delay="800">
          {asset.subtitle}
        </Subtitle>

        <div
          style={{
            display: "flex",
            flexDirection: !biggerThenMobile ? "column" : "row",
          }}
        >
          <SpecialButton
            rel="nofollow  noreferrer"
            target="_blank"
            aria-label="order food"
            href={`https://www.dineout.is/himalayanspice/order?lng=${router.locale}`}
            variant="contained"
          >
            {t("orderFood")}
          </SpecialButton>
          <SpecialButton
            rel="nofollow  noreferrer"
            target="_blank"
            aria-label="book table"
            href={`https://www.dineout.is/himalayanspice?isolation=true&lang=${router.locale}`}
            variant="contained"
          >
            {t("bookTable")}
          </SpecialButton>
          <SpecialButton
            rel="nofollow  noreferrer"
            target="_blank"
            aria-label="book table"
            href={`https://www.dineout.is/himalayanspice/order?lng=${router.locale}&menuid=895`}
            variant="contained"
          >
            {t("buyGiftCard")}
          </SpecialButton>
        </div>
      </Content>
    </Parent>
  );
};

const Parent = styled.div`
  background-image: url(${(props) => props.url});
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Logo = styled.div`
  background-image: url(${(props) => props.url});
  height: 100px;
  width: 248px;
  background-repeat: no-repeat;
  background-size: contain;

  @media screen and (min-width: 768px) {
    height: 200px;
    width: 496px;
  }
`;

const Content = styled.div`
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
`;

const Title = styled.div`
  font-size: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: center;
`;

const Subtitle = styled.div`
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 32px;
  text-align: center;
`;

const SpecialButton = styled(Button)`
  && {
    width: 148px;
    font-size: 16px;
    color: #fff;
    background-color: #a3231b;
    border-radius: 0px;
    font-weight: bold;
    height: 56px;
    border: none;
    box-shadow: none;
    margin: 8px;

    @media screen and (min-width: 768px) {
      border-radius: 4px;
      margin-right: 0px;
    }
  }
`;

export default Hero;
