import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { smoothScroll } from "../../helper/scrollToElement";
import { useContentfulContext } from "../../context/ContentfulContext";

const Menu = () => {
  const { t } = useTranslation();
  const navLinks = [
    { linkText: t("menu"), link: ".menus" },
    { linkText: t("openingHours"), link: ".opening-hours" },
    { linkText: t("about"), link: ".about" },
  ];
  const [open, setOpen] = useState(false);

  let lunchMenu = undefined;
  let setMenus = undefined;

  const { allEntries } = useContentfulContext();
  if (allEntries.items) {
    const asset = allEntries.items.find(
      (entry) => entry.sys.contentType.sys.id === "lunchMenu",
    );
    lunchMenu = asset?.fields;

    const setMenusEntry = allEntries.items.find(
      (entry) => entry.sys.contentType.sys.id === "setMenus",
    );
    setMenus = setMenusEntry?.fields;
  }

  return (
    <ul className="navbar-nav">
      {navLinks.map((item, i) => (
        <li
          key={i}
          className={`menu-item ${item.child ? "menu-item-has-children" : ""} `}
          onClick={() => smoothScroll(item.link, 2000, "header")}
        >
          <a>{item.linkText}</a>
        </li>
      ))}
      {lunchMenu && (
        <li className={`menu-item`}>
          <a
            href={`https:${lunchMenu.pdf.fields.file.url}`}
            target="_blank"
            rel="noreferrer"
          >
            {lunchMenu.title}
          </a>
        </li>
      )}
      {setMenus && (
        <li
          className={`menu-item`}
          onMouseOver={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <a className={open ? "set-menus" : ""}>{setMenus.title}</a>
          {open && (
            <>
              <li
                style={{
                  marginTop: 0,
                  position: "absolute",
                  width: 300,
                }}
              >
                {setMenus.setMenus.map((item, i) => (
                  <a
                    key={i}
                    className={open ? "dropwdownhover" : ""}
                    href={`https:${item.fields.file.url}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      fontSize: 12,
                      padding: 4,
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  >
                    {item.fields.title}
                  </a>
                ))}
              </li>
            </>
          )}
        </li>
      )}
    </ul>
  );
};

export default Menu;
