/* eslint-disable max-len */
import useMediaQuery from "../../helper/useMediaQuery";
import { useContentfulContext } from "../../context/ContentfulContext";

const Categories = () => {
  const isDesktop = useMediaQuery(992); // false => tablet or mobile
  const { allEntries } = useContentfulContext();
  const menus = [];
  if (allEntries.items) {
    const assets = allEntries.items.filter(
      (entry) => entry.fields.menuName !== undefined,
    );
    assets.forEach((menu) => {
      const { menuName, subtitle, menuImage, identifier } = menu.fields;
      const { url } = menuImage.fields.file;
      const singleMenu = { menuName, subtitle, url, identifier, menu: [] };
      menu.fields.items.forEach((menuItem) => {
        const singleItem = { ...menuItem.fields };
        singleMenu.menu.push(singleItem);
      });
      menus.push(singleMenu);
    });
  }
  menus.sort((a, b) => a.identifier - b.identifier);

  return (
    <div className="menus">
      <div className="section" style={{ paddingTop: 80 }}>
        {menus.map((it, i) => (
          <div key={i}>
            <div className="ct-categories">
              {/* Category Start */}
              <div className="ct-category">
                {isDesktop && i % 2 == 0 && (
                  <div
                    className="ct-category-bg"
                    style={{
                      // backgroundImage: "url(" + "Himalayan-Lamb-Sekuwa.jpg)",
                      backgroundImage: `url(${it.url})`,
                    }}
                  />
                )}

                <div className="ct-category-content">
                  <div className="ct-category-content-inner">
                    <div className="section-title-wrap section-header">
                      {/* <h5 className="custom-primary">{it.title}</h5> */}
                      <h2 className="title">{it.menuName} </h2>
                      <p className="subtitle">{it.subtitle}</p>
                    </div>
                    {/* Menu Start */}
                    <div className="row">
                      {it.menu.map((item, i) => (
                        <div key={i} className="col-lg-6">
                          <div className="ct-mini-menu-item">
                            <div
                              className="ct-mini-menu-top"
                              style={{
                                justifyContent: "space-between",
                                alignItems: "start",
                              }}
                            >
                              <h5 style={{ lineHeight: "initial" }}>
                                {item.name}
                              </h5>
                              <span
                                className="custom-primary"
                                style={{
                                  // flexShrink: 0,
                                  whiteSpace: "nowrap",
                                  minWidth: "fit-content",
                                  lineHeight: "initial",
                                  marginLeft: 8,
                                }}
                              >
                                {new Intl.NumberFormat()
                                  .format(item.price)
                                  .replace(",", ".")}{" "}
                                kr.
                              </span>
                            </div>
                            <div className="ct-mini-menu-bottom">
                              <p>{item.description}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* Menu End */}
                  </div>
                </div>
                {(!isDesktop || i % 2 == 1) && (
                  <div
                    className="ct-category-bg"
                    style={{
                      backgroundImage: `url(${it.url})`,
                    }}
                  />
                )}
              </div>
              {/* Category End */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
