/* eslint-disable max-len */
/* eslint-disable react/display-name */
import React, { Fragment } from "react";
import HeaderComponent from "../../helper/Navigationhelper";
import Mobilemenu from ".//Mobilemenu";
import Menu from "./Menu";
import classNames from "classnames";
import LanguageIcons from "../../helper/LanguageIcons";
// import Button from "@mui/material/Button";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useMediaQuery from "../../helper/useMediaQuery";
import { useState } from "react";
import { useContentfulContext } from "../../context/ContentfulContext";

const headerHOC = (Component) => {
  return (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    let lunchMenu = undefined;
    let setMenus = undefined;

    const { allEntries } = useContentfulContext();
    if (allEntries.items) {
      const asset = allEntries.items.find(
        (entry) => entry.sys.contentType.sys.id === "lunchMenu",
      );
      lunchMenu = asset?.fields;

      const setMenusEntry = allEntries.items.find(
        (entry) => entry.sys.contentType.sys.id === "setMenus",
      );
      setMenus = setMenusEntry?.fields;
    }
    const isTablet = useMediaQuery(768);
    const isDesktop = useMediaQuery(992);
    return (
      <Component
        isMobile={!isTablet}
        isDesktop={isDesktop}
        open={open}
        setOpen={setOpen}
        lunchMenu={lunchMenu}
        setMenus={setMenus}
        t={t}
        {...props}
      />
    );
  };
};

const getSize = () => {
  const nav = document.querySelector("header");
  const size = nav.getBoundingClientRect().height;
  if (typeof size === "number") {
    return size;
  }
  return 0;
};

class Header extends HeaderComponent {
  constructor(props) {
    super(props);
    this.updateSize = this.updateSize.bind(this);
  }
  state = {
    backgroundHeight: 0,
  };

  componentDidMount() {
    this.setState({ backgroundHeight: getSize() });
    window.addEventListener("resize", this.updateSize);
  }

  componentDidUpdate() {
    const val = getSize();
    if (this.state.backgroundHeight !== val) {
      this.setState({ backgroundHeight: getSize() });
    }
  }

  updateSize() {
    this.setState({ backgroundHeight: getSize() });
  }

  render() {
    // const { t, isMobile } = this.props;
    const {  isMobile } = this.props;

    return (
      <Fragment>
        <aside
          className={classNames("main-aside", { open: this.state.navmethod })}
        >
          <Mobilemenu
            navigationmenu={this.props.navigationmenu}
            closeMenu={() => this.toggleNav()}
          />
        </aside>
        <div className="aside-overlay aside-trigger" onClick={this.toggleNav} />
        {/* Header Start */}
        <header className="main-header header-2">
          <div
            className="container"
            style={{ backgroundColor: "#fffaed", padding: 0 }}
          >
            <nav className="navbar">
              <div style={{ display: "flex" }}>
                <div
                  className="aside-toggler aside-trigger"
                  onClick={this.toggleNav}
                >
                  <span />
                  <span />
                  <span />
                </div>
              </div>

              <Menu navigationmenu={this.props.navigationmenu} />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* {!isDesktop && this.props.lunchMenu && (
                  <li className={`menu-item`}>
                    <a
                      href={`https:${this.props.lunchMenu.pdf.fields.file.url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {this.props.lunchMenu.title}
                    </a>
                  </li>
                )} */}
                {/* {!isDesktop && this.props.setMenus && (
                  <li
                    className={`menu-item`}
                    onMouseOver={() => this.props.setOpen(true)}
                    onMouseLeave={() => this.props.setOpen(false)}
                  >
                    <a className={this.props.open ? "set-menus" : ""}>
                      {this.props.setMenus.title}
                    </a>
                    {this.props.open && (
                      <>
                        <div
                          style={{
                            marginTop: 0,
                            width: 148,
                            position: "absolute",
                            right: 0,
                            background: "#fffaed",
                          }}
                        >
                          {this.props.setMenus.setMenus.map((item, i) => (
                            <a
                              key={i}
                              className="set-menus dropwdownhover"
                              href={`https:${item.fields.file.url}`}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                fontSize: 12,
                                padding: 4,
                                paddingLeft: 8,
                                paddingRight: 8,
                              }}
                            >
                              {item.fields.title}
                            </a>
                          ))}
                        </div>
                      </>
                    )}
                  </li>
                )} */}
                {!isMobile ? (
                  <>
                    <LangWrapper>
                      <LanguageIcons size={24} />
                    </LangWrapper>
                    {/* <SpecialButton
                      rel="nofollow  noreferrer"
                      target="_blank"
                      aria-label="order food"
                      href="https://www.dineout.is/himalayanspice/order"
                      variant="contained"
                      style={{ marginRight: 16 }}
                    >
                      {t("orderFood")}
                    </SpecialButton>
                    <SpecialButton
                      rel="nofollow  noreferrer"
                      target="_blank"
                      aria-label="book table"
                      href="https://www.dineout.is/himalayanspice?isolation=true"
                      variant="contained"
                    >
                      {t("bookTable")}
                    </SpecialButton> */}
                  </>
                ) : null}
              </div>
            </nav>
          </div>
        </header>
        <header
          style={{
            backgroundColor: "transparent",
            height: this.state.backgroundHeight,
          }}
        ></header>
        {/* Header End */}
      </Fragment>
    );
  }
}

// const SpecialButton = styled(Button)`
//   && {
//     width: 132px;
//     font-size: 14px;
//     color: #fff;
//     background-color: #a3231b;
//     border-radius: 0px;
//     font-weight: bold;
//     height: 56px;
//     border: none;
//     box-shadow: none;
//     margin-right: -16px;

//     @media screen and (min-width: 768px) {
//       border-radius: 4px;
//       margin-right: 0px;
//     }
//   }
// `;

const LangWrapper = styled.div`
  margin: 0px 16px 0px 16px;
`;

export default headerHOC(Header);
