import React from "react";
import LanguageIcons from "../../helper/LanguageIcons";
import styled from "styled-components";
import { smoothScroll } from "../../helper/scrollToElement";
import { useTranslation } from "react-i18next";
import { useContentfulContext } from "../../context/ContentfulContext";

const Mobilemenu = ({ closeMenu }) => {
  const { t } = useTranslation();
  const navLinks = [
    { linkText: t("menu"), link: ".menus" },
    { linkText: t("openingHours"), link: ".opening-hours" },
    { linkText: t("about"), link: ".about" },
  ];

  let lunchMenu = undefined;
  let setMenus = undefined;

  const { allEntries } = useContentfulContext();
  if (allEntries.items) {
    const asset = allEntries.items.find(
      (entry) => entry.sys.contentType.sys.id === "lunchMenu",
    );
    lunchMenu = asset?.fields;

    const setMenusEntry = allEntries.items.find(
      (entry) => entry.sys.contentType.sys.id === "setMenus",
    );
    setMenus = setMenusEntry?.fields;
  }

  return (
    <>
      <div className="aside-scroll">
        {lunchMenu && (
          <NavLink>
          <a
          href={`https:${lunchMenu.pdf.fields.file.url}`}
          target="_blank"
          rel="noreferrer"
        >
              {lunchMenu.title}
        </a>
          </NavLink>
        )}
        {setMenus && (
            <NavLink>
              {setMenus.title}
            </NavLink>
            )}
          {setMenus && setMenus.setMenus && setMenus.setMenus.map((item, i) => (
          <SubNavLink key={i}>
                  <a
                    href={`https:${item.fields.file.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.fields.title}
                  </a>
                    </SubNavLink>
                ))}
        {navLinks.length > 0
          ? navLinks.map((item, i) => (
              <NavLink
                key={i}
                className="menu-item"
                onClick={() => {
                  closeMenu();
                  smoothScroll(item.link, 2000, "header");
                }}
              >
                  {item.linkText}
              </NavLink>
            ))
          : null}
        <LangWrapper>
          <LanguageIcons size={24} />
        </LangWrapper>
      </div>
    </>
  );
};

const NavLink = styled.div`
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.75;
  padding: 8px 0px;

  text-transform: uppercase;
  cursor: pointer;
`;

const SubNavLink = styled(NavLink)`
  font-size: 14px;
  padding-left: 8px;
  padding-bottom: 4px;
  padding-top: 4px;
`

const LangWrapper = styled.div``;

export default Mobilemenu;
